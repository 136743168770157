.logo>img{
    height: 2.5rem;
    width: 2.5rem;
}
.footer-detail>div>:nth-child(2){
    font-size: 0.7rem;
    font-style: italic;
}
.footer-detail>div{
    align-items: center;
}
.footer>div>span{
    font-size: 0.65rem;
}
.footer-detail>:first-child{
    font-size: 1.2rem;
    font-weight: 500;
}
.footer-detail>div>:nth-child(2):hover{
    cursor: pointer;
}
.footer-detail>div>svg:hover{
    cursor: pointer;
}